<template>
  <MutateScheduleMolecule
    :title="$t('titles.addSchedule')"
    :assetsByRecipient="assetsByRecipient"
    @cancel="$emit('close')"
    @confirm="createSchedule"
  />
</template>

<script>
import { DateTime } from 'luxon'
import { MutateScheduleMolecule } from '@common/components'

import MY_CALENDARS_LIST_QUERY from '#/graphql/calendar/myCalendarsWithMinimalSchedules.gql'
import CALENDAR_QUERY from '#/graphql/calendar/myCalendarMinimal.gql'
import CALENDAR_SHOW_QUERY from '#/graphql/calendar/show.gql'
import CREATE_SCHEDULE_MUTATION from '#/graphql/calendar/schedule/create.gql'
import ASSETS_BY_RECIPIENT_QUERY from '#/graphql/operations/assets/_assetsByRecipient.gql'

export default {
  props: {
    calendarId: {
      type: String,
      required: true,
    },
  },
  components: {
    MutateScheduleMolecule,
  },
  data() {
    return {
      assetsByRecipient: [],
      calendar: null,
    }
  },
  methods: {
    getDay(schedule) {
      if (!schedule.scheduleStartDateTime || !schedule.timezone) {
        return null
      }
      return DateTime.fromJSDate(schedule.scheduleStartDateTime).setZone(schedule.timezone)
    },
    getStart(schedule) {
      const day = this.getDay(schedule)
      if (day === null) {
        return null
      }
      const dayStr = day.toFormat(`yyyy-LL-dd`)
      return DateTime.fromFormat(`${dayStr} ${schedule.startTime}`, 'yyyy-LL-dd HH:mm:ss')
    },
    getEnd(schedule) {
      const day = this.getDay(schedule)
      const start = this.getStart(schedule)
      if (day === null) {
        return null
      }
      const dayStr = day.toFormat(`yyyy-LL-dd`)
      const end = DateTime.fromFormat(`${dayStr} ${schedule.endTime}`, 'yyyy-LL-dd HH:mm:ss')
      if (end.toMillis() <= start.toMillis()) {
        return end.plus({ days: 1 })
      }
      return end
    },
    getDuration(schedule) {
      const start = this.getStart(schedule)
      const end = this.getEnd(schedule)
      if (!start || !end) {
        return null
      }
      const duration = end.diff(start)
      if (duration.isLuxonDuration) {
        return duration
      }
      return null
    },
    getRRule(schedule) {
      const scheduleStartDateTime = DateTime.fromJSDate(schedule.scheduleStartDateTime).setZone(schedule.timezone, {
        keepLocalTime: true,
      })
      const start = this.getStart(schedule)
      const duration = this.getDuration(schedule)
      if (scheduleStartDateTime.invalid || !duration || !start) {
        return null
      }
      const durationInMillis = duration.toMillis()
      if (durationInMillis < 1) {
        return null
      }
      const rrule = {
        frequency: schedule.frequency,
        interval: parseInt(schedule.interval),
        start: scheduleStartDateTime,
        duration: durationInMillis,
        byDayOfWeek: schedule.byDayOfWeek,
        byHourOfDay: [start.hour],
        byMinuteOfHour: [start.minute],
        bySecondOfMinute: [start.second],
      }
      return rrule
    },
    async createSchedule(schedule) {
      const rrule = this.getRRule(schedule)
      const recurrenceData = {
        start: rrule?.start?.toISO(),
        end: rrule?.end?.toISO(),
        frequency: rrule?.frequency,
        interval: rrule?.interval,
        duration: rrule?.duration,
        bySecondOfMinute: {
          set: rrule?.bySecondOfMinute,
        },
        byMinuteOfHour: {
          set: rrule?.byMinuteOfHour,
        },
        byHourOfDay: {
          set: rrule?.byHourOfDay,
        },
        byDayOfWeek: {
          set: rrule?.byDayOfWeek,
        },
      }
      const createPerformanceGoals = schedule.performanceGoals.map(({ name, assetFilterIds, performanceGoalRules }) => {
        const createRules = performanceGoalRules.map(({ assetDimensionId, min, max, threshold, thresholdTypeEnum }) => ({
          assetDimensionId,
          min,
          max,
          threshold,
          thresholdTypeEnum,
        }))
        return {
          name,
          assetFilterIds: {
            set: assetFilterIds,
          },
          performanceGoalRules: {
            create: createRules,
          },
        }
      })
      const data = {
        type: schedule.type,
        name: schedule.name,
        createShiftReport: schedule.createShiftReport,
        createTyresHealthReport: false,
        calendar: {
          connect: {
            id: this.calendarId,
          },
        },
        timezone: schedule.timezone,
        useTimezoneInRSchedule: true,
        recurrences: {
          create: [recurrenceData],
        },
        assignedAssets: {
          create: schedule.assignedAssets,
        },
        performanceGoals: {
          create: createPerformanceGoals,
        },
      }
      try {
        const res = await this.$apollo.mutate({
          mutation: CREATE_SCHEDULE_MUTATION,
          variables: {
            data,
          },
          update: async (store, { data }) => {
            const newSchedule = data.createSchedule
            const { myCalendars } = store.readQuery({
              query: MY_CALENDARS_LIST_QUERY,
            })
            const calendar = myCalendars.find(c => c.id === this.calendarId)
            calendar.schedules.push(newSchedule)
            store.writeQuery({
              query: MY_CALENDARS_LIST_QUERY,
              data: {
                myCalendars,
              },
            })
          },
          refetchQueries: [
            {
              query: CALENDAR_SHOW_QUERY,
              variables: {
                where: {
                  id: this.calendarId,
                },
              },
            },
          ],
        })
        this.$emit('created', res)
      } catch (e) {
        // do nothing now
      }
    },
  },
  apollo: {
    assetsByRecipient: {
      query: ASSETS_BY_RECIPIENT_QUERY,
      variables() {
        return {
          where: {
            recipientId: this.calendar.ownerId,
            recipientType: this.calendar.ownerType,
          },
        }
      },
      skip() {
        return !this.calendar?.ownerType || !this.calendar?.ownerId
      },
    },
    calendar: {
      query: CALENDAR_QUERY,
      variables() {
        return {
          where: {
            id: this.calendarId,
          },
        }
      },
      skip() {
        return !this.calendarId
      },
    },
  },
}
</script>
