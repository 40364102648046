<template>
  <CreateScheduleOverlayStyled>
    <CreateScheduleOrganism :calendarId="args.calendarId" @close="$emit('close')" @created="handleCreated" />
  </CreateScheduleOverlayStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import CreateScheduleOrganism from '@/pages/PlanningApp/Organisms/CreateScheduleOrganism.vue'

const CreateScheduleOverlayStyled = styled('div')`
  width: 100%;
  height: calc(100% - 50px - 1rem);
  max-width: 800px;
  padding: 1rem;
  padding-top: 50px;
  .modal {
    height: 100%;
  }
`

export default {
  components: {
    CreateScheduleOverlayStyled,
    CreateScheduleOrganism,
  },
  props: {
    args: {
      type: Object,
    },
  },
  methods: {
    handleCreated(payload) {
      const scheduleId = payload?.data?.createSchedule?.id
      this.args?.createdCallback?.({ scheduleId })
    },
  },
}
</script>
